const createBaseURL = (url, project, version) => {
    return `${url}/${project}/${version}/`;
  };
  
  // Contoh penggunaan:
  const url = "https://api.dev-klhk.rnbteknologiindonesia.com";
  const project = "api";
  const version = "v1";
  const baseURL = createBaseURL(url, project, version);
  
export default baseURL;